export const mutations = {
  groupingDevices(state, payload) {
    state.unlinkedDevices = [];
    for(const payloadDevice of payload) {
      if(payloadDevice.flat) {
        if(payloadDevice.flat.property === state.currentProperty._id) {
          if(state.linkedDevices.length != 0) {
            for(const linkedDevice of state.linkedDevices) {
              if(linkedDevice._id === state.linkedDevices[state.linkedDevices.length - 1]._id && linkedDevice._id != payloadDevice._id) {
                payloadDevice.showDetails = false;
                payloadDevice.flushingProcess = false;
                state.linkedDevices.push(payloadDevice);
              } else if(linkedDevice._id === payloadDevice._id) {
                break;
              }
            }
          } else {
            payloadDevice.showDetails = false;
            payloadDevice.flushingProcess = false;
            state.linkedDevices.push(payloadDevice);
          }
        } else {
          state.linkedDevices = [];
        }
      } else {
        state.unlinkedDevices.push(payloadDevice);
        for(const linkedDevice in state.linkedDevices) {
          if(state.linkedDevices[linkedDevice]._id === payloadDevice._id) {
            state.linkedDevices.splice(linkedDevice, 1);
          }
        }
      }
    }
    state.unlinkedDevices.sort(function(firstDate, secondDate) {
      const c: any = new Date(firstDate.started_at);
      const d: any = new Date(secondDate.started_at);
      return d - c;
    })
  },
  updateDevice(state, payload) {
    if(payload.flat) {
      if(payload.flat.property == state.currentProperty._id) {
        for(const linkedDevice in state.linkedDevices) {
          if(state.linkedDevices[linkedDevice]._id === payload._id) {
            payload.showDetails = state.linkedDevices[linkedDevice].showDetails;
            payload.flushingProcess = state.linkedDevices[linkedDevice].flushingProcess;
            payload.flat = state.linkedDevices[linkedDevice].flat;
            state.linkedDevices[linkedDevice] = payload;
          }
        }
      }
    } else {
      for(const unlinkedDevice in state.unlinkedDevices) {
        if(state.unlinkedDevices[unlinkedDevice]._id === payload._id) {
          state.unlinkedDevices[unlinkedDevice] = payload;
          break;
        } else if(state.unlinkedDevices[unlinkedDevice]._id === state.unlinkedDevices[state.unlinkedDevices.length - 1]._id && state.unlinkedDevices[unlinkedDevice]._id != payload._id) {
          state.unlinkedDevices.push(payload);
          state.unlinkedDevices.sort(function(firstDate, secondDate) {
            const c: any = new Date(firstDate.started_at);
            const d: any = new Date(secondDate.started_at);
            return d - c;
          })
        }
      }
    }
  },
  updateFunctionalTestsList(state, payload) {
    if(payload.length != 0) {
        state.currentFunctionalTestsList = [];
        payload.forEach(test => {
          if(test.status === 3) {
            test.showDetails = true;
          } else {
            test.showDetails = false;
          }
          if(state.currentFunctionalTestsList.length === 0) {
            state.currentFunctionalTestsList.push(test);
          } else {
            state.currentFunctionalTestsList.unshift(test);
          }
        });
    } else {
      state.currentFunctionalTestsList = payload;
    }
  },
  addTestingDevice(state, payload) {
    if(state.currentFunctionalTestsList.length !== 0) {
      if(state.currentFunctionalTestsList[0]._id === payload._id) {
        state.currentFunctionalTestsList[0] = payload;
        state.currentFunctionalTestsList[0].showDetails = true;
      } 
    }
  },
  cleanFunctionalTestsList(state, payload) {
    state.currentFunctionalTestsList = payload;
  },
  updateCurrentFlush(state, payload) {
    for(const linkedDevice of state.linkedDevices) {
      if(Object.keys(payload).length != 0) {
        if(payload.device === linkedDevice._id && linkedDevice.showDetails === true) {
          state.currentLastFlushProcess = null;
          state.currentLastFlushProcess = payload;
          linkedDevice.flushingProcess = true;
        }
      }
    }
  },
  cleanCurrentFlushProcess(state, payload) {
    state.currentLastFlushProcess = payload;
  },
  updateLogs(state, payload) {
    if(payload.length !== 0) {
      state.logs = []
      payload.forEach(log => {
        if(state.logs.length === 0) {
          log.showComment = false;
          state.logs.push(log);
        } else {
          log.showComment = false;
          state.logs.unshift(log);
        }
      });
      state.logs.sort(function(firstDate, secondDate) {
        const c: any = new Date(firstDate.created_at);
        const d: any = new Date(secondDate.created_at);
        return d - c;
      })
    } else {
      state.logs = payload;
    }
  
  },
  updateLog(state, payload) {
    if(state.logs.length !== 0) {
      for(const log of state.logs) {
        if(payload._id === log._id) {
          log.comment = payload.comment;
          break;
        } else if(log._id === state.logs[state.logs.length - 1]._id && log._id != payload._id) {
          state.logs.push(payload);
        }
      }
      state.logs.sort(function(firstDate, secondDate) {
        const c: any = new Date(firstDate.created_at);
        const d: any = new Date(secondDate.created_at);
        return d - c;
      })
    } else {
      state.logs.push(payload);
    }
  },
  cleanLogs(state, payload) {
    state.logs = payload;
  },
  setCurrentTestingDevice(state, payload) {
    if(state.currentTestingDevice._id === payload._id && Object.keys(payload).length !== 0) {
      state.currentTestingDevice.parameters = payload.parameters;
    } else {
      state.currentTestingDevice = payload;
    }
  },
  setCurrentProperty(state, payload) {
    state.currentProperty = payload;
  },
  updateCurrentProperty(state, payload) {
    if(state.currentProperty._id === payload._id) {
      state.currentProperty = payload;
    }
  }
};
  