export const mutations = {
  getAllFlats(state, payload) {
    state.flats = [];
    for(const payloadFlat of payload) {
      if(!payloadFlat.device) {
        state.flats.push(payloadFlat);
      }
    }
  },
  setCurrentFlushingProcessConnection(state, payload) {
    state.currentFlushingProcessConnection = payload;
  },
  setProperties(state, payload) {
    state.properties = payload;
  },
};
  