import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

export const state = {
  flats: [],
  properties: [],
};

export const flat = {
  state,
  getters,
  actions,
  mutations
};
